<template>
  <div class="conectividadPublica">
        <!-- CONTENIDO -->
    <div class="container">
         <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
     <div class="row top-buffer">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SCT</a></li>
            <li>Portal Cobertura Universal</li>
            <li class="active">Programa de Conectividad en Sitios Públicos 2024</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7" id="logotipo">
          <img src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom:30px;">Programa de Conectividad en Sitios Públicos 2024</h1>
          <hr class="red">
        </div>
      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <p class="text-justify">El Programa de Conectividad en Sitios Públicos 2024 es el instrumento de política pública que hace visible, a través de sus Bases de Datos la demanda de 3,621 sitios públicos prioritarios por conectar ubicados en las Localidades de Atención Prioritaria de Cobertura Social para garantizar el servicio de Internet gratuito y con ello contribuir al ejercicio del derecho humano consagrado en el artículo 6° Constitucional.</p>
		      <p class="text-justify">Para integrar la Base de Datos de Sitios Públicos Prioritarios por Conectar del <strong>PCSP 2024</strong> se utilizaron 4 criterios de elegibilidad no excluyentes, incluyendo uno nuevo relacionado con la población femenina en las Localidades de Atención Prioritaria de Cobertura Social definidas por el Programa de Cobertura Social 2023-2024.</p>
		  	  <p class="text-justify">Lo anterior permite que los operadores de servicios de telecomunicaciones tomen como referencia este conjunto de sitios prioritarios en sus planes de despliegue de redes y de oferta asequible de Internet, especialmente CFE Telecomunicaciones e Internet para Todos, toda vez que son la demanda de conectividad prioritaria en el país.</p>
          <p class="text-justify">Otro elemento relevante, destacado por el PCSP 2024 para la elección de sitios públicos es la asequibilidad, es decir, el nivel de precios de la oferta de conectividad en las localidades urbanas y rurales, lo que forma parte de los gastos de los hogares y de las personas para contar con el servicio de acceso a Internet.</p>
          <p class="text-justify">Finalmente, “Aldeas Inteligentes, Bienestar Sostenible” es el proyecto de la SICT a través del cual se contribuye a que las comunidades ubicadas en áreas rurales, o sin ofertas de cobertura, puedan aprovechar al máximo las posibilidades y los recursos de la conectividad a Internet para elevar su calidad de vida y nivel de bienestar, sumando al logro de los Objetivos de Desarrollo Sostenible de las Naciones Unidas.</p>
          <p class="text-justify"></p>
          
		  
		 
		  <hr>
		  
		    
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/Programa_de_Conectividad_en_Sitios_Publicos_2024.pdf" download="Conectividad_2024.pdf">Descargar archivo PDF</a>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/Anexo_1_PCSP_2024.xlsx" download="Anexo_1_PCSP_2024.xlsx">Anexo 1 Programa de Conectividad Sitios Públicos</a>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/Anexo_2 PCSP_2024_prioritarios.xlsx" download="Anexo_2 PCSP_2024_prioritarios.xlsx">Anexo 2 Programa de Conectividad Sitios Públicos-Prioritarios</a>
        </div>
      </div>
     
       <div class="row">
        <div class="col-md-12 text-right">
          <hr />
          <a class="btn btn-default" type="button" href="/conectividad-sitios-publicosInter">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'conectividadPublica',
  data(){
    return{
      pdfData:''
    }
  },
 mounted(){
   
 },
  methods: {
    

  },
}
</script>

